export const formConfList = {
	1: [
		{
			label: '合同模板',
			prop: 'contract',
			type: 'select',
			options: []
		},
		{
			label: '保证金',
			prop: 'bzj',
			type: 'input'
		},
		{
			label: '服务费',
			prop: 'service',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: 'pay',
			type: 'select',
			options: []
		}
	],
	2: [
		{
			label: '保证金金额',
			prop: 'bzj',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: 'pay',
			type: 'select',
			options: []
		}
	],
	3: [
		{
			label: '服务费金额',
			prop: '2',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: '4',
			type: 'select',
			options: []
		}
	],
	4: [
		{
			label: '佣金链接',
			prop: '2',
			type: 'input'
		},
	],
	5: [
		{
			label: '收货地址',
			prop: 'addressLists',
			type: 'select',
			options: []
		},
		{
			label: '收件人',
			prop: 'username',
			type: 'input',
			disabled: true
		},
		{
			label: '收件地址',
			prop: 'address',
			type: 'input',
			disabled: true
		},
		{
			label: '联系电话',
			prop: 'mobile',
			type: 'input',
			disabled: true
		},
		{
			label: '寄样要求',
			prop: 'require',
			type: 'input'
		},
	],
	6: [
		{
			label: '备注',
			prop: 'memo',
			type: 'input'
		},
	],
	7: [
		{
			label: '成交笔数',
			prop: 'cjbs',
			type: 'input'
		},
		{
			label: '成交数量',
			prop: 'cjs',
			type: 'input'
		},
		{
			label: '销售总额',
			prop: 'xse',
			type: 'input'
		},
		{
			label: '直播状态',
			prop: 'type',
			search: true,
			type: 'select',
			options: [
				{
					name: '已完成',
					id: 7
				},
			]
		},
	],
	8: [
		{
			label: '回寄快递单号',
			prop: 'dh',
			type: 'input'
		},
	],
}

export const addScheduleTableConf = [
	{
		label: '日期',
		prop: '21',
	},
	{
		label: '直播主题',
		prop: '22',
	},
	{
		label: '意向商品总数',
		prop: '23',
	},
	{
		label: '已确定商品总数',
		prop: '24',
	},
	{
		label: '负责人',
		prop: '25',
	},
	{
		label: '提醒日期',
		prop: '26',
	},
	{
		label: '备注',
		prop: '27',
	},
]

export const ActiveTableConf = [
	{
		label: '主播',
		prop: '21',
	},
	{
		label: '活动名称',
		prop: 'zb_name',
	},
	{
		label: '负责人',
		prop: 'username',
	},
	{
		label: '排期商品',
		prop: 'goodsNum',
	},
	{
		label: '操作',
		prop: '27',
	},
]


export const editformConfList = [
	{
		label: '主题',
		prop: '1',
		type: 'input',
	},
	{
		label: '活动模板',
		prop: '2',
		type: 'select',
		options: []
	},
	{
		label: '主播名称',
		prop: '1',
		type: 'input',
		disabled: true
	},
	{
		label: '直播状态',
		prop: '2',
		type: 'select',
		options: []
	},
	{
		label: '负责人',
		prop: '2',
		type: 'select',
		options: []
	},
	{
		label: '排期日期',
		prop: '2',
		type: 'date',
	},
	{
		label: '时间范围',
		prop: '2',
		type: 'daterange',
	},
	{
		label: '目标商品数',
		prop: '2',
		type: 'input',
	},
	{
		label: '备注',
		prop: '2',
		type: 'textarea',
	},
]